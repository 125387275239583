export default function throttle(fn, threshhold, thisScope) {
  let last;
  let deferTimer;
  return function () {
    const context = thisScope || this;

    const now = +new Date();
    const args = arguments;
    if (last && now < last + threshhold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}
