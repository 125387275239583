import React from 'react';
import {ThemeProvider} from 'styled-components';


import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import SEO from '../components/seo';
import AllFixedElements from '../components/AllFixedElements';
import BackgroundFloatingObjects from '../components/BackgroundFloatingObjects';
import SectionFooter from '../components/SectionFooter';
import SectionAboutUs from '../components/SectionAboutUs';

import GlobalStyle from '../components/layoutCss';


class AboutPage extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />
        <BackgroundFloatingObjects isSubPage={true}/>
        <ThemeProvider theme={themes.themeMain}>
          <ThemeContainer>
            <SEO
              title='About'
              path={this.props.path}
            />
            <AllFixedElements current={'about'}  isCaseStudy={true}  />
            <MainContainer>
              <SectionAboutUs />
              <SectionFooter />
            </MainContainer>
          </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}
export default AboutPage;
