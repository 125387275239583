import React from 'react';

import { MediumHeaderTypo, BasicParagraphTypo, AllCapsSubTypo } from '../commonStyles/typoTheme';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerCenter } from '../commonStyles/TextStyles';
import { WideImage } from '../commonStyles/ImagesStyles';
import { SectionAboutMargin } from '../commonStyles/SectionsStyle';

import { AboutUsLocationImage, AboutUsLocationButton } from './styles/AboutStyles';
import { CenteredButton } from '../commonStyles/ButtonStyles';

import Button from './Button';
import HorizontalLine from './HorizontalLine';

import locationImg from '../images/location.jpg';


class SectionAboutUsLocation extends React.PureComponent {

  render() {
      return (
        <SectionAboutMargin>
          <AboutUsLocationImage>
            <WideImage bgImg={'url(' + locationImg + ')'}/>
          </AboutUsLocationImage>
          <MiddleContainer>
            <TextContainerCenter>
              <MediumHeaderTypo>
                Location: Remote.
              </MediumHeaderTypo>
              <BasicParagraphTypo>
                Yes, really. You won't find us in the office. That's because we love the remote lifestyle. It boosts our creativity and helps us avoid morning rush hours, so we can concentrate on what's really important. And, we can collaborate with talented people, doesn't matter where they live.
              </BasicParagraphTypo>
              <AllCapsSubTypo>
                New business? Rebranding a cafe? We are available:
              </AllCapsSubTypo>
            </TextContainerCenter>
            <AboutUsLocationButton>
              <CenteredButton>
                <Button name={'bcc@border3px.com'} href={'mailto:bcc@border3px.com'} />
              </CenteredButton>
            </AboutUsLocationButton>
            <HorizontalLine margin={'0'}/>
          </MiddleContainer>
        </SectionAboutMargin>
      );
  }
}

export default SectionAboutUsLocation;
