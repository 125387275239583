import React from 'react';

import { MediumHeaderTypo, SubTypo, BasicParagraphTypo, AllCapsSubTypo } from '../commonStyles/typoTheme';
import { SectionAboutMargin } from '../commonStyles/SectionsStyle';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerColumns, SmallColumnsContainer, TextColumn, TextColumnListTile, TextColumnListDot, TextColumnListTitle } from '../commonStyles/TextStyles';


export const skillsIdentity = [
  { id: 1, skill: 'Art direction'},
  { id: 2, skill: 'Brand identity'},
  { id: 3, skill: 'Concepts and research'},
  { id: 4, skill: 'Digital and print'},
  { id: 5, skill: 'Naming'},
  { id: 6, skill: 'Photography'}
];


export const skillsProduct = [
  { id: 1, skill: 'User Interface'},
  { id: 2, skill: 'User Experience'},
  { id: 3, skill: 'Design systems'},
  { id: 4, skill: 'Web design'},
  { id: 5, skill: 'App design'},
  { id: 6, skill: 'Web development'},
  { id: 7, skill: 'App development'}
];




class SectionAboutUsDescription extends React.PureComponent {

renderSkillsList(skills) {
return (
  <TextColumnListTile key={skills.id}>
    <SubTypo>
        <TextColumnListDot>
      	</TextColumnListDot>
        {skills.skill}
    </SubTypo>
  </TextColumnListTile>
  );
}

  render() {
      return (
        <SectionAboutMargin isFirst={true}>
          <MiddleContainer>
            <TextContainerColumns>
              <TextColumn hasPaddingRight={true}>
                <MediumHeaderTypo>
                  Hello!
                </MediumHeaderTypo>
                <BasicParagraphTypo>
                  We’re a team of curiosity-led, multidisciplinary creatives, who work with people and businesses to help them create unique brand identities and tailor-made digital solutions.
                </BasicParagraphTypo>
              </TextColumn>
              <SmallColumnsContainer>
                <TextColumn isLargeColumn={true} hasBottomMargin={true}>
                  <TextColumnListTitle>
                    <AllCapsSubTypo>
                      Design
                    </AllCapsSubTypo>
                  </TextColumnListTitle>
                  {skillsIdentity.map(skills => this.renderSkillsList(skills))}
                </TextColumn>
                <TextColumn isLargeColumn={true}>
                  <TextColumnListTitle>
                    <AllCapsSubTypo>
                      Product
                    </AllCapsSubTypo>
                  </TextColumnListTitle>
                  {skillsProduct.map(skills => this.renderSkillsList(skills))}
                </TextColumn>
              </SmallColumnsContainer>
            </TextContainerColumns>
          </MiddleContainer>
        </SectionAboutMargin>
      );
  }
}

export default SectionAboutUsDescription;
