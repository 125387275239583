import React from 'react';

import throttle from '../helpers/throttle';

if (typeof window === 'undefined') {
  global.window = {};
}

class ScrollHoc extends React.PureComponent {

  constructor() {
    super();

    this.setWindowPosition = this.setWindowPosition.bind(this);
    this.scrollStuff = this.scrollStuff.bind(this);

    this.state = {
      windowPosition: 100,
      isMounted: false,
    };
  }




  setWindowPosition() {
    if (this.state.isMounted) this.setState({
      windowPosition: parseInt(window.pageYOffset)
    });
  }

  scrollStuff() {
    throttle(this.setWindowPosition(), 10);
  }

  componentDidMount() {


    this.setState({
      isMounted: true
    });

    setTimeout(() => {
      this.setWindowPosition()
      window.addEventListener('scroll', this.scrollStuff);
    }, 2)
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.scrollStuff);
  }

  render() {

    const {
      initalStyle,
    } = this.props;

    const {
      windowPosition,
    } = this.state;

    const calculatePosition = function(payload) {
      if (!payload) return '';
      const { value, speed = 2 } = payload;
      if (!value) return 'inital';
      return value - windowPosition * (speed * 0.1) ;
    };

    const styleContent = {
      position: initalStyle.position ? initalStyle.position : 'fixed',
      zIndex: initalStyle.zIndex ? initalStyle.zIndex : '0',
      width: initalStyle.width ? initalStyle.width : '100%',
      top: calculatePosition(initalStyle.top) + 'vh',
      left: calculatePosition(initalStyle.left)+ 'vw',
      transition: initalStyle.transition ? initalStyle.transition : ''
    }

    return (
      <div
        style={styleContent}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ScrollHoc;
