import styled from "styled-components";

export const AboutUsLocationImage = styled.div`
  margin-bottom: 10rem;

  /* 1024px */
  @media (min-width: 64em) {
    margin-bottom: 20rem;
  }
`;

export const AboutUsLocationButton = styled.div`
  display: block;
  position: relative;
  width: fit-content;
  margin: 7rem auto 12rem auto;
`;


export const AboutUsTeamImageContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: ${ props => props.isFirst ? '0' : '5rem'};
  border-top: ${ props => props.isFirst ? '' : '1px dashed'};
  border-color: ${ props => props.theme.secondaryColor };

  /* 1280px */
  @media (min-width: 80em) {
    margin-top: ${ props => props.isFirst ? '0' : '-30rem'};
    padding: 0;
    border: none;
  }
`;

export const AboutUsTeamImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  float: left;

  /* 1280px */
  @media (min-width: 80em) {
    width: 40%;
    float: ${ props => props.isRight ? 'right' : 'left'};
  }
`;

export const AboutUsTeamImageText = styled.div`
  width: 100%;
  display: block;
  position: relative;
  padding: 5rem 0;
`;
