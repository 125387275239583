import React from 'react';

import { MediumHeaderTypo, SubTypo, AllCapsSubTypo } from '../commonStyles/typoTheme';
import { SectionAboutMargin } from '../commonStyles/SectionsStyle';
import { TeamImage } from '../commonStyles/ImagesStyles';
import { MiddleContainer } from '../commonStyles/Containers';

import { AboutUsTeamImageContainer, AboutUsTeamImage, AboutUsTeamImageText } from './styles/AboutStyles';

import radoslaw from '../images/radoslaw.jpg';
import maria from '../images/maria.jpg';
import david from '../images/david.jpg';

export const teamMembers = [
  { id: 1, bgImg: radoslaw, name: 'Radoslaw Wroblewski', job: 'Founder', isFloatRight: true, isFirst: true},
  { id: 2, bgImg: maria, name: 'Maria Wasik', job: 'Head of Design',  isFloatRight: false},
  { id: 3, bgImg: david, name: 'David Badovinac', job: 'Account Manager',  isFloatRight: true}
];

function renderTeam(members, i) {
  return (
    <AboutUsTeamImageContainer key={'AboutUsTeamImageContainer-'+members.id} isFirst={members.isFirst}>
      <AboutUsTeamImage isRight={members.isFloatRight}>
        <TeamImage bgImg={'url(' + members.bgImg + ')'}/>
        <AboutUsTeamImageText>
          <MediumHeaderTypo>
            {members.name}
          </MediumHeaderTypo>
          <AllCapsSubTypo>
            {members.job}
          </AllCapsSubTypo>
          <SubTypo>
            <a href={'mailto:' + members.mail}  target={'_target'}>{members.mail}</a>
          </SubTypo>
        </AboutUsTeamImageText>
      </AboutUsTeamImage>
    </AboutUsTeamImageContainer>
  );
}

class SectionAboutUsTeam extends React.PureComponent {


  render() {
      return (
        <>
        <SectionAboutMargin>
          <MiddleContainer>
            {teamMembers.map(members => renderTeam(members))}
          </MiddleContainer>
        </SectionAboutMargin>
        </>
      );
  }
}

export default SectionAboutUsTeam;
