import React from 'react';

import SectionAboutUsDescription from './SectionAboutUsDescription';
import SectionAboutUsLocation from './SectionAboutUsLocation';
import SectionAboutUsTeam from './SectionAboutUsTeam';

class SectionAboutUs extends React.PureComponent {

  render() {
      return (
        <>
          <SectionAboutUsDescription />
          <SectionAboutUsLocation />
          <SectionAboutUsTeam />
        </>
      );
  }
}

export default SectionAboutUs;
