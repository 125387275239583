import styled from "styled-components";

export const BackgroundFloatingObjectsContainer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`;

export const BackgroundCircle = styled.div`
  display: block;
  position: fixed;
  left: -30vh;
  top: 0;
  width: 100vh;
  height: 100vh;
  border-radius: 50vh;
  border: 1px solid rgba(38, 36, 36, 0.1);
`;

export const BackgroundFloatingObjectCenter = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const BackgroundFloatingObject = styled.div`
  display: none;

  /* 1024px */
  @media (min-width: 64em) {
    display: block;
    position: absolute;
    width: 15%;
    transform: translate(-50%, -50%);
  }
`;
