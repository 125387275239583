import React from 'react';

import HorizontalLineStyle from './styles/HorizontalLineStyle';

class HorizontalLine extends React.PureComponent {
  render() {

    const {
      margin
    } = this.props;


    return (
      <HorizontalLineStyle margin={margin}>
      </HorizontalLineStyle>
    );
  }
}

export default HorizontalLine;
