import React from 'react';

import throttle from '../helpers/throttle';
import ScrollHoc from '../components/ScrollHoc';

import { BackgroundFloatingObjectsContainer, BackgroundFloatingObject, BackgroundCircle, BackgroundFloatingObjectCenter } from './styles/BackgroundFloatingObjectsStyles';

import FloatingObject1 from '../images/separate/1.png';
import FloatingObject2 from '../images/separate/2.png';
import FloatingObject3 from '../images/separate/3.png';
import FloatingObject5 from '../images/separate/5.png';
import FloatingObject6 from '../images/separate/6.png';
import FloatingObject7 from '../images/separate/7.png';


export const arrayIndex = [
  { id: 1, src: FloatingObject1, initialX: 10, initialY: 45, speedY: 4, speedX: 1},
  { id: 2, src: FloatingObject2, initialX: 35, initialY: 15, speedY: 3, speedX: -3},
  { id: 5, src: FloatingObject5, initialX: 65, initialY: 86, speedY: -9, speedX: 2},
  { id: 6, src: FloatingObject6, initialX: 0, initialY: 40, speedY: 5, speedX: 3},
  { id: 7, src: FloatingObject7, initialX: 90, initialY: 25, speedY: -7, speedX: -2}
];

export const arraySubPage = [
  { id: 2, src: FloatingObject2, initialX: 90, initialY: 25, speedY: 4, speedX: 1},
  { id: 3, src: FloatingObject3, initialX: 35, initialY: 2, speedY: 3, speedX: -3},
  { id: 7, src: FloatingObject7, initialX: 65, initialY: 86, speedY: -9, speedX: 2},
  { id: 6, src: FloatingObject6, initialX: 0, initialY: 40, speedY: 5, speedX: 3},
];


class BackgroundFloatingObjects extends React.PureComponent {
  constructor() {
    super();
    this.setPosition = this.setPosition.bind(this);
    this.myRefs = [];
    this.state = {
      layout: []
    };
  }

  renderObjects(imageObjects, movePos) {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let positionLeft = (( imageObjects.initialX / 100) * windowWidth);
    let positionTop = (( imageObjects.initialY / 100) * windowHeight);
    const rectWidth = Math.abs(movePos.cursorX - positionLeft);
    const rectHeight = Math.abs(movePos.cursorY - positionTop);
    const distanceX = rectWidth;
    const distanceY = rectHeight;
    const distanceSqrt = Math.round(Math.sqrt(rectWidth*rectWidth + rectHeight*rectHeight));
    const angle = Math.atan2(distanceY,distanceX);
    positionLeft = positionLeft + Math.cos(angle) * Math.min(10,(1000/distanceSqrt));
    positionTop = positionTop + Math.sin(angle) * Math.min(10,(1000/distanceSqrt));
    return (
      <ScrollHoc key={imageObjects.id}
        initalStyle={
          {
            position: 'relative',
            left: {
              value: 1,
              speed: imageObjects.speedX / 2
              },
            top: {
              value: 2,
              speed: imageObjects.speedY / 2
              }
            }
        }
      >
        <BackgroundFloatingObject ref={(ref) => { this.myRefs[imageObjects.id] = ref; return true; }}
          style={
            {
              left: (positionLeft / windowWidth) * 100 + '%',
              top: positionTop
            }
          }
        >
          <img src={imageObjects.src} width={'100%'} alt={''} />
        </BackgroundFloatingObject>
      </ScrollHoc>
    );
  }

  setPosition(e) {
    const posX = e ? e.clientX : 1;

    const posY = e ? e.clientY : 1;


    const posPayload = {
      cursorX: posX,
      cursorY: posY
    };

    this.setState({
      layout: this.props.isSubPage ? arraySubPage.map(imageObjects => this.renderObjects(imageObjects, posPayload)) : arrayIndex.map(imageObjects => this.renderObjects(imageObjects, posPayload))
    });
  }

  componentDidMount() {
    const posPayload = {
      cursorX: 100,
      cursorY: 50
    };
    this.setState({
      layout: this.props.isSubPage ? arraySubPage.map(imageObjects => this.renderObjects(imageObjects, posPayload)) : arrayIndex.map(imageObjects => this.renderObjects(imageObjects, posPayload))
    });
    if (window.innerWidth >= 1024) window.addEventListener('mousemove', throttle(this.setPosition, 15));
  }


  render() {

    return (
      <>
        <BackgroundCircle>
        </BackgroundCircle>
        <BackgroundFloatingObjectsContainer>
          <BackgroundFloatingObjectCenter>
            {this.state.layout}
          </BackgroundFloatingObjectCenter>
        </BackgroundFloatingObjectsContainer>
      </>
      );
  }
}

export default BackgroundFloatingObjects;
